import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import stravaConnectButton from "../assets/connect_with_strava.png";
import DOMPurify from "dompurify";
import {
  FaSquareReddit,
  FaUserAstronaut,
  FaPersonRunning,
  FaWandMagicSparkles,
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareThreads,
  FaSquareXTwitter,
} from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f7f7f7;
  font-family: "Arial", sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #fc4c02;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  max-width: 500px;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.6;
  margin-top: 2rem;
`;

const EmailInput = styled.input`
  width: 300px;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.84rem;
`;

const ConnectButton = styled.img`
  cursor: ${(props) => (props.isValid ? "pointer" : "not-allowed")};
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};
  transition: opacity 0.3s ease;
  width: 212px;
  &:hover {
    opacity: ${(props) => (props.isValid ? 0.8 : 0.5)};
  }
`;

const FAQSection = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
`;

const FAQQuestion = styled.button`
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #fc4c02;
  }

  &:after {
    content: ${(props) => (props.expanded ? '"−"' : '"+"')};
    font-size: 1.2rem;
  }
`;

const FAQAnswer = styled.div`
  font-size: 0.9rem;
  color: #888;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  line-height: 1.4;

  a {
    color: #fc4c02;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMessage = styled.div`
  background-color: #ffe6e6;
  color: #d8000c;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 600px;
`;

const Alert = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 12px;
  border-radius: 4px;
  // margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
  max-width: 600px;
  border: 1px solid #ffeeba;
`;

const CommentSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0.8rem 0;
`;

// const CommentTitle = styled.h3`
//   font-size: 1.5rem;
//   color: #333;
//   margin-bottom: 0.2rem;
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   justify-content: center;
//   text-align: center;
//   margin-top: 0rem;
// `;

const SliderWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  .slick-track {
    display: flex !important;
    align-items: center !important; // Center align items
  }
  .slick-slide {
    height: auto;
    margin: 3px; // Reduced margin for less padding between tiles
    > div {
      height: 100%;
      display: flex;
      align-items: center; // Center content vertically
    }
  }
  .slick-dots li button:before {
    color: #fc4c02;
  }
  .slick-dots li.slick-active button:before {
    color: #fc4c02;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #fc4c02;
    font-size: 24px;
  }
  .slick-prev {
    left: -24px;
  }
  .slick-next {
    right: -24px;
  }
  .slick-list {
    margin: 0 -5px; // Adjusted margin to match padding
  }
  .slick-slide > div {
    padding: 0 5px; // Consistent padding
  }
`;

const CommentTile = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center; // Center vertically
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const PlatformIcon = styled.span`
  display: flex;
  align-items: center;
`;

const CommentAuthor = styled.span`
  font-weight: bold;
  color: #ff4500;
`;

const CommentContent = styled.p`
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
  margin-bottom: 0.4rem;
`;

// const AboutLink = styled.a`
//   display: block;
//   margin-top: 0.5rem;
//   margin-bottom: 1rem;
//   color: #fc4c02;
//   text-decoration: underline;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

const FeatureSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 1.5rem auto;
`;

const FeatureSectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.8rem;
  text-align: center;
`;

const FeatureSectionSubtitle = styled.h4`
  font-size: 1rem;
  color: #666;
  margin-top: 0rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const FeatureList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

const FeatureItem = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: calc(30% - 1rem);
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  color: #fc4c02;
  margin-top: 0rem;
`;

const FeatureName = styled.h4`
  font-size: 1.1rem;
  color: #333;
  margin: 0.5rem 0;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin: 0.5rem 0;
`;

const CTAButton = styled(Link)`
  background-color: #fc4c02;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
  font-weight: 600;

  &:hover {
    background-color: #e34400;
    transform: translateY(-1px) translateX(1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

// const MarathonSection = styled.div`
//   width: calc(100% - 64px);
//   margin: 1.5rem auto;
//   text-align: center;
//   background-color: #f0f0f0;
//   padding: 1rem;
//   border-radius: 8px;
// `;

// const MarathonTitle = styled.h3`
//   font-size: 1.5rem;
//   color: #333;
//   margin-bottom: 0.8rem;
// `;

// const MarathonSubtitle = styled.h4`
//   font-size: 1rem;
//   color: #666;
//   margin-bottom: 1rem;
// `;

// const MarathonLink = styled.a`
//   color: #fc4c02;
//   text-decoration: underline;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const SearchContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 0.5rem;
//   margin-top: 1.5rem;
//   font-size: 1rem; // Consistent font size
//   font-family: "Arial", sans-serif; // Consistent font family

//   @media (max-width: 600px) {
//     flex-direction: column; // Stack items vertically on small screens
//     gap: 0.2rem;
//   }
// `;

// const StyledText = styled.span`
//   font-size: 1rem;
//   color: #333;
// `;

// const MonthDropdown = styled.select`
//   margin: 0 0.5rem;
//   padding: 0.5rem;
//   border-radius: 5px;
//   border: 1px solid #ccc;
//   font-size: 1rem;
// `;

// const GoButton = styled.button`
//   background-color: #fc4c02;
//   color: white;
//   padding: 0.6rem 1rem;
//   border-radius: 5px;
//   border: none;
//   cursor: pointer;
//   font-size: 1rem;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #e34400;
//   }
// `;

const StatsSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin: 2rem 0;
  width: 100%;
  max-width: 600px;

  @media (max-width: 480px) {
    gap: 1.5rem;
    flex-direction: column;
  }
`;

const StatContainer = styled.div`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #fc4c02;
`;

const StatLabel = styled.div`
  font-size: 1.4rem;
  color: #333;
  margin-top: 0.5rem;
  font-weight: 600;
`;

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [expandedFAQ, setExpandedFAQ] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // const [selectedMonth, setSelectedMonth] = useState("November");

  const athleteCount = useSpring({
    from: { number: 0 },
    to: { number: 10000 },
    delay: 200,
    config: { duration: 2000 },
  });

  const roastCount = useSpring({
    from: { number: 0 },
    to: { number: 30000 },
    delay: 200,
    config: { duration: 2000 },
  });

  useEffect(() => {
    const existingToken = localStorage.getItem("strava_token");
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const error = params.get("error");
    const email = params.get("email");

    if (error) {
      if (error === "access_denied") {
        setErrorMessage(
          "You've denied access to Strava. To use Roast My Strava, please allow access to your Strava data."
        );
      } else if (error === "strava_api" || error === "rate_limit") {
        setErrorMessage(
          "Oops! We're experiencing high traffic and have hit Strava's rate limit. We've saved your email and will notify you when we're back up and running. Thanks for your patience!"
          // "Oops! We're experiencing high traffic and have hit Strava's rate limit. For the best chance of success, please come back at 8pm EST / 5pm PST when our rate limit resets for the day. We've saved your email and will notify you when we're back up and running in full force. Thanks for your patience!"
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      } else if (error === "internal_server") {
        setErrorMessage(
          "Oops! We hit a wall. Our servers are catching their breath. We've saved your email and will notify you when we're back up. Please try again later."
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      }
    } else if (token) {
      localStorage.setItem("strava_token", token);
      setIsAuthenticated(true);
    } else if (existingToken) {
      setIsAuthenticated(true);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleLogin = () => {
    if (isEmailValid) {
      window.location.href = `https://roast-my-strava-backend-production.up.railway.app/login?email=${encodeURIComponent(
        email
      )}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isEmailValid) {
      handleLogin();
    }
  };

  const faqData = [
    {
      question: "What information do you store?",
      answer:
        "I keep it simple: just your email (for updates and related projects from <a href='https://jasonkuperberg.com/roast-my-strava' target='_blank' rel='noopener'>me</a>) and basic Strava info like your name and profile picture.",
    },
    {
      question: "How does this work?",
      answer:
        "It's pretty straightforward: you connect your Strava account, this allows me to fetch some basic stats and recent activities via the Strava API, then the AI (similar to ChatGPT) generates a unique response just for you.",
    },
    {
      question: "What's next for Roast My Strava and how can I contact you?",
      answer:
        "I'm exploring new ideas to make your running, cycling, and fitness journey more engaging and productive. Many of these concepts are AI related, but not all. Got any cool suggestions or just want to chat? I'd love to hear from you. Just send a note to <a href='mailto:roastmystrava@gmail.com'>roastmystrava@gmail.com</a> or find my contact info on <a href='https://jasonkuperberg.com' target='_blank' rel='noopener'>my personal website</a>.",
    },
    {
      question: "Who are you?",
      answer:
        "I'm <a href='https://jasonkuperberg.com' target='_blank' rel='noopener'>Jason</a>, an entrepreneur, software developer, and lifelong runner (2:46 Marathon PR). When I'm not building or learning something new, I'm probably out running. Let me know if you're in NYC and want to go for a run!",
    },
  ];

  const toggleFAQ = (index) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  const comments = [
    // {
    //   author: "u/SzechuanSaucelord",
    //   content:
    //     "This was fun, now if AI could interpret all my Strava data and develop coaching feedback that would be amazing.",
    //   url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnhvlo7/",
    //   platform: "Reddit",
    // },

    {
      author: "@running_bucky",
      content: "Get your Strava roasted… made me chuckle!",
      url: "https://www.instagram.com/p/DAVTqwRtx3c/",
      platform: "Instagram",
    },
    {
      author: "Derek M.",
      content: "RoastMyStrava.com is the laugh I needed tonight 😂",
      url: null,
      platform: "Facebook",
    },
    {
      author: "u/RealHek",
      content: "I'm in bits! This was the highlight of my day! Well done.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lni9msk/",
      platform: "Reddit",
    },
    {
      author: "@trail_tortoise_running",
      content:
        "Has anyone seen the RoastMyStrava.com website yet!? This is pure gold!",
      url: "https://www.threads.net/@trail_tortoise_running/post/DAojW_NPf7Y",
      platform: "Threads",
    },
    {
      author: "Jill H.",
      content:
        "The 'Roast My Strava' AI is supposed to be mean, but this one is not all that mean. 😆",
      url: null,
      platform: "Facebook",
    },
    {
      author: "@MelindaHoward4",
      content: "Have y’all seen this #StravaRoast? Hilarious!",
      url: "https://twitter.com/MelindaHoward4/status/1841581218518667458",
      platform: "XTwitter",
    },
    {
      author: "u/FlyMyPretty",
      content:
        "I'm quite impressed that it used my stats AND my photo (which is me and a chicken).",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnho8sz/",
      platform: "Reddit",
    },
    {
      author: "@stantherunningman",
      content:
        "I couldn’t resist. I had to “Roast my Strava”… enjoy the laugh.",
      url: "https://www.instagram.com/p/DAgg3AlvISr",
      platform: "Instagram",
    },
    {
      author: "Paul N.",
      content: "Roast My Strava is funny! Go on, do it and have a laugh!",
      url: null,
      platform: "Facebook",
    },
    {
      author: "u/Zealousideal_Rich239",
      content:
        "Funny roasts with a good balance of compliments and punches so it doesn't actually hurt or offend. LOL.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lp5g1lk/",
      platform: "Reddit",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    initialSlide: Math.floor(Math.random() * comments.length), // Start at a random slide
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const features = [
    {
      name: "Strava Profile Roast",
      description: "Get a custom roast based on your Strava profile and stats.",
      icon: <FaUserAstronaut />,
      cta: "Get Roasted",
      link: "/dashboard",
    },
    {
      name: "Strava Title Generator",
      description:
        "Generate creative (or descriptive) titles for your Strava activities.",
      icon: <FaWandMagicSparkles />,
      cta: "Generate Titles",
      link: "/strava-title-generator",
    },
    {
      name: "Strava Activity Analysis",
      description: "Feedback on your latest Strava activities.",
      icon: <FaPersonRunning />,
      cta: "Analyze My Activities",
      link: "/activity",
    },
  ];

  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "Reddit":
        return <FaSquareReddit color="#ff4500" size={18} />;
      case "Facebook":
        return <FaSquareFacebook color="#1877f2" size={18} />;
      case "Threads":
        return <FaSquareThreads color="#000000" size={18} />;
      case "Instagram":
        return <FaSquareInstagram color="#E1306C" size={18} />;
      case "XTwitter":
        return <FaSquareXTwitter color="#000000" size={18} />;
      default:
        return null;
    }
  };

  // const handleMonthChange = (e) => {
  //   setSelectedMonth(e.target.value);
  // };

  // const handleGoClick = () => {
  //   window.location.href = `https://marathons2025.com/${selectedMonth.toLowerCase()}?utm_source=roastmystrava`;
  // };

  return (
    <Container>
      <Helmet>
        <title>Roast My Strava | Commentary on Your Strava Profile</title>
        <meta
          name="description"
          content="Connect your Strava account and get witty, AI-generated commentary on your running and cycling activities. A fun way to celebrate your fitness journey!"
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, roastmystrava, Strava AI,running, cycling, humor, fitness"
        />
      </Helmet>
      <Title>
        <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
          Roast My Strava
        </Link>
      </Title>{" "}
      <Subtitle>Get Roasted for Your Athletic Achievements</Subtitle>
      {/* <Alert>
        🏃‍♂️💨 Whoa, we just smashed our traffic PR! We're experiencing high
        volume due to a few popular social media posts.
        <br />
        <br />
        You might hit a wall trying to connect due to Strava API limitations.
        We're pushing through this endurance test and appreciate your patience!
        <br />
        <br />
        Strava team, if you're seeing this, we'd love a limit increase 👀
      </Alert> */}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <EmailInput
        type="email"
        name="email"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyPress}
        placeholder="Enter your email"
        autoComplete="email"
      />
      <ConnectButton
        src={stravaConnectButton}
        alt="Connect with Strava"
        onClick={handleLogin}
        isValid={isEmailValid}
      />
      <Description>
        Connect your Strava account and our AI will create personalized
        commentary based on your Strava profile and activities.
      </Description>
      <StatsSection>
        <StatContainer>
          <StatNumber>
            <animated.span>
              {athleteCount.number.to(
                (n) => `${Math.floor(n).toLocaleString()}+`
              )}
            </animated.span>
          </StatNumber>
          <StatLabel>Athletes Roasted</StatLabel>
        </StatContainer>
        <StatContainer>
          <StatNumber>
            <animated.span>
              {roastCount.number.to(
                (n) => `${Math.floor(n).toLocaleString()}+`
              )}
            </animated.span>
          </StatNumber>
          <StatLabel>Roasts Generated</StatLabel>
        </StatContainer>
      </StatsSection>
      <CommentSection>
        {/* <CommentTitle>🚴‍♂️ Community Reactions 🏃‍♀️</CommentTitle> */}
        <SliderWrapper>
          <Slider {...sliderSettings}>
            {comments.map((comment, index) => (
              <CommentTile
                key={index}
                href={comment.url}
                target="_blank"
                rel="noopener"
              >
                <CommentHeader>
                  <PlatformIcon>
                    {getPlatformIcon(comment.platform)}
                  </PlatformIcon>
                  <CommentAuthor>{comment.author}</CommentAuthor>
                </CommentHeader>
                <CommentContent>{comment.content}</CommentContent>
              </CommentTile>
            ))}
          </Slider>
        </SliderWrapper>
      </CommentSection>
      {/* <AboutLink
        href="https://jasonkuperberg.com/roast-my-strava"
        target="_blank"
        rel="noopener noreferrer"
      >
        About Roast My Strava
      </AboutLink> */}
      <Alert>
        What started as a fun side project to share with my friends has taken
        off much more than expected!
        <br />
        <br />
        Over the past few weeks I've connected with cyclists in Rio,
        ultrarunners in Indiana, and parkrunners in London. I am grateful for
        every athlete who has connected their Strava account and shared their
        results on social media.
        <br />
        <br />
        Due to high traffic, we may hit a roadblock from time to time. If you
        try to connect and are unable to, I'll save your email and will notify
        you when we're back up and running. Thanks for your patience and
        support!
        <br />
        <br />
        <a
          href="https://jasonkuperberg.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fc4c02", textDecoration: "underline" }}
        >
          🏃🏻‍♂️ Jason
        </a>
        {/* <br />
        <a
          href="https://jasonkuperberg.com/roast-my-strava"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fc4c02", textDecoration: "underline" }}
        >
          Check out the story of Roast My Strava
        </a> */}
      </Alert>
      <FeatureSection>
        <FeatureSectionTitle>AI for Strava</FeatureSectionTitle>
        <FeatureSectionSubtitle>
          (since before Athlete Intelligence was a thing 😉)
        </FeatureSectionSubtitle>
        <FeatureList>
          {features.map((feature, index) => (
            <FeatureItem key={index}>
              <FeatureIcon>{feature.icon}</FeatureIcon>
              <FeatureName>{feature.name}</FeatureName>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <CTAButton to={feature.link}>{feature.cta}</CTAButton>
            </FeatureItem>
          ))}
        </FeatureList>
      </FeatureSection>
      <FAQSection>
        {faqData.map((faq, index) => (
          <FAQItem key={index}>
            <FAQQuestion
              onClick={() => toggleFAQ(index)}
              expanded={expandedFAQ === index}
            >
              {faq.question}
            </FAQQuestion>
            {expandedFAQ === index && (
              <FAQAnswer
                dangerouslySetInnerHTML={{
                  __html: (() => {
                    const sanitizedHTML = DOMPurify.sanitize(faq.answer, {
                      ADD_ATTR: ["target"],
                    });
                    console.log(sanitizedHTML); // Debug: Log the sanitized HTML
                    return sanitizedHTML;
                  })(),
                }}
              />
            )}
          </FAQItem>
        ))}
      </FAQSection>
      {/* <MarathonSection>
        <MarathonTitle>
          Ready to Run a Marathon Next Year?{" "}
          <MarathonLink
            href="https://marathons2025.com?utm_source=roastmystrava"
            target="_blank"
            rel="noopener"
          >
            Find Your 2025 Marathon Here!
          </MarathonLink>
        </MarathonTitle>
        <MarathonSubtitle>
          Looking for the best marathons to run in 2025? Visit{" "}
          <MarathonLink
            href="https://marathons2025.com?utm_source=roastmystrava"
            target="_blank"
            rel="noopener"
          >
            Marathons2025.com
          </MarathonLink>
          — your go-to resource for a complete 2025 marathon calendar.
          <br />
          <br />
          Easily search for marathons by city, month, or if it's a Boston
          Qualifier. Get registration info, course details, and race-day
          conditions for top events, including the{" "}
          <MarathonLink
            href="https://marathons2025.com/marathon/new-york-city-marathon?utm_source=roastmystrava"
            target="_blank"
            rel="noopener"
          >
            2025 New York City Marathon
          </MarathonLink>
          , and many more.
        </MarathonSubtitle>
        <SearchContainer>
          <StyledText>Find</StyledText>
          <MonthDropdown onChange={handleMonthChange} value={selectedMonth}>
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </MonthDropdown>
          <StyledText>2025 Marathons</StyledText>
          <GoButton onClick={handleGoClick}>Go</GoButton>
        </SearchContainer>
      </MarathonSection> */}
    </Container>
  );
}

export default Home;
