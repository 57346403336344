import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import ActivityRoast from "./components/ActivityRoast";
import AnalyzeActivity from "./components/AnalyzeActivity";
import TitleGenerator from "./components/TitleGenerator";
import NewHome from "./components/NewHome";
// import Chat from "./components/Chat";
function RedirectToExternalUrl({ url }) {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NewHome />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/activity-roast" element={<ActivityRoast />} />
        <Route path="/activity" element={<AnalyzeActivity />} />
        <Route path="/strava-title-generator" element={<TitleGenerator />} />
        {/* <Route path="/chat" element={<Chat />} /> */}
        <Route
          path="/about"
          element={
            <RedirectToExternalUrl url="https://jasonkuperberg.com/roast-my-strava" />
          }
        />
        <Route path="/home" element={<NewHome />} />
        <Route path="/old" element={<Home />} />
        {/* any other link should redirect to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
