import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import stravaConnectButton from "../assets/connect_with_strava.png";
import {
  FaSquareReddit,
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareThreads,
  FaSquareXTwitter,
} from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import JasonRunning from "../assets/JasonRunning.jpg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-height: 100svh;
  min-height: 100dvh;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  position: relative;
  overflow: auto;
  background: #fc4c02;
  justify-content: space-between;
  padding: 0;
`;

const MainContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: calc(90vw - 4rem);
  max-width: 680px;
  margin: auto;
`;

const Title = styled.h1`
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
  @media (max-width: 480px) {
    font-size: 2rem;
  }
  margin-bottom: 1rem;
  text-align: center;
  color: #21211f;
  font-weight: 700;
`;

const Subtitle = styled.h2`
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-size: 1.6rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
  font-weight: 500;
  line-height: 1.4;
`;

const SecondarySubtitle = styled.h4`
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-style: italic;
  font-weight: 400;
`;

const EmailInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const ConnectButton = styled.img`
  cursor: ${(props) =>
    props.isValid && !props.isLoading ? "pointer" : "not-allowed"};
  opacity: ${(props) => (props.isValid && !props.isLoading ? 1 : 0.5)};
  transition: opacity 0.3s ease;
  width: 212px;
  &:hover {
    opacity: ${(props) => (props.isValid && !props.isLoading ? 0.8 : 0.5)};
  }
`;

const ErrorMessage = styled.div`
  background-color: #ffe6e6;
  color: #d8000c;
  padding: 10px;
  border-radius: 4px;
  margin: 1rem auto;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const ErrorMessageLink = styled.a`
  color: #d8000c;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
`;

const TopTicker = styled.div`
  position: relative;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px 0;
  z-index: 3;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  overflow: hidden;
`;

const TopTickerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  animation: scrollLeft 240s linear infinite;
  white-space: nowrap;
  width: max-content;
  color: #21211f;

  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  /* Pause animation on hover */
  &:hover {
    animation-play-state: paused;
  }
`;

const RoastExcerpt = styled.span`
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  line-height: 1.4;
  transition: color 0.2s ease;
  user-select: none;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;

  &:hover {
    color: #fc4c02;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem; /* 12px */
  }
`;

const TickerDivider = styled.span`
  color: #fc4c02;
  font-size: 0.75rem;
  opacity: 0.8;
`;

const CommentTile = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 8px;
  margin: auto 1rem;
  cursor: ${(props) => (props.url ? "pointer" : "default")};
  transition: transform 0.2s;
  height: fit-content;
  user-select: none;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const PlatformIcon = styled.span`
  display: flex;
  align-items: center;
`;

const CommentAuthor = styled.span`
  font-weight: 600;
  color: #ff4500;
`;

const CommentContent = styled.p`
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
  margin-bottom: 0.4rem;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: 500;
`;

const CommentSection = styled.div`
  width: calc(100% - 106px);
  margin: 1rem auto;
`;

const SliderWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  .slick-track {
    display: flex !important;
    align-items: center !important;
  }
  .slick-slide {
    height: auto;
    margin: 3px;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .slick-dots li button:before {
    color: #fc4c02;
  }
  .slick-dots li.slick-active button:before {
    color: #fc4c02;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    color: rgba(255, 255, 255, 0.9);
    font-size: 24px;
  }
  .slick-prev {
    left: -24px;
  }
  .slick-next {
    right: -24px;
  }
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 1.4rem;
  background: #f0f0f0;
  padding: 4px;
  border-radius: 24px;
  position: relative;
  width: 200px;
`;

const TabSlider = styled.div`
  position: absolute;
  height: calc(100% - 8px);
  width: calc(50% - 4px);
  background: #fc4c02;
  border-radius: 20px;
  transition: transform 0.3s ease;
  transform: translateX(
    ${(props) => (props.activeTab === "about" ? "98%" : "2%")}
  );
  top: 4px;
`;

const Tab = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  background: transparent;
  color: ${(props) => (props.active ? "white" : "#333")};
  border-radius: 20px;
  cursor: pointer;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-weight: 600;
  transition: color 0.2s ease;
  flex: 1;
  position: relative;
  z-index: 1;
  font-size: 0.95rem;

  &:hover {
    color: ${(props) => (props.active ? "white" : "#666")};
  }
`;

const AboutContent = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  color: #333;
  line-height: 1.6;
  max-width: 680px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0.4rem;
    margin-bottom: 0;
  }
`;

const AboutTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`;

const AboutText = styled.p`
  margin: 0;
  font-size: 1rem;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  font-weight: 500;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AboutImage = styled.img`
  width: 160px;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  border: 2px solid #fc4c02;

  @media (max-width: 768px) {
    // display: none;
  }
`;

const ImageCaption = styled.a`
  font-size: 0.75rem;
  color: #666;
  font-style: italic;
  text-align: center;
  margin-top: 0.5rem;
  display: block;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, sans-serif;
  text-decoration: none;
  @media (max-width: 768px) {
    // display: none;
  }
  &:hover {
    color: #fc4c02;
    cursor: pointer;
  }
`;

const comments = [
  // {
  //   author: "u/SzechuanSaucelord",
  //   content:
  //     "This was fun, now if AI could interpret all my Strava data and develop coaching feedback that would be amazing.",
  //   url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnhvlo7/",
  //   platform: "Reddit",
  // },
  {
    author: "@running_bucky",
    content: "Get your Strava roasted… made me chuckle!",
    url: "https://www.instagram.com/p/DAVTqwRtx3c/",
    platform: "Instagram",
  },
  {
    author: "Derek M.",
    content: "RoastMyStrava.com is the laugh I needed tonight 😂",
    // url: null,
    platform: "Facebook",
  },
  {
    author: "u/RealHek",
    content: "I'm in bits! This was the highlight of my day! Well done.",
    url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lni9msk/",
    platform: "Reddit",
  },
  {
    author: "@trail_tortoise_running",
    content:
      "Has anyone seen the RoastMyStrava.com website yet!? This is pure gold!",
    url: "https://www.threads.net/@trail_tortoise_running/post/DAojW_NPf7Y",
    platform: "Threads",
  },
  {
    author: "Jill H.",
    content:
      "The 'Roast My Strava' AI is supposed to be mean, but this one is not all that mean. 😆",
    // url: null,
    platform: "Facebook",
  },
  {
    author: "@MelindaHoward4",
    content: "Have y’all seen this #StravaRoast? Hilarious!",
    url: "https://twitter.com/MelindaHoward4/status/1841581218518667458",
    platform: "XTwitter",
  },
  {
    author: "u/FlyMyPretty",
    content:
      "I'm quite impressed that it used my stats AND my photo (which is me and a chicken).",
    url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnho8sz/",
    platform: "Reddit",
  },
  {
    author: "@stantherunningman",
    content: "I couldn’t resist. I had to “Roast my Strava”… enjoy the laugh.",
    url: "https://www.instagram.com/p/DAgg3AlvISr",
    platform: "Instagram",
  },
  {
    author: "Paul N.",
    content: "Roast My Strava is funny! Go on, do it and have a laugh!",
    // url: null,
    platform: "Facebook",
  },
  {
    author: "u/Zealousideal_Rich239",
    content:
      "Funny roasts with a good balance of compliments and punches so it doesn't actually hurt or offend.",
    url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lp5g1lk/",
    platform: "Reddit",
  },
];

const roastExcerpts = [
  {
    content:
      "Your Strava pace is so slow, the Elizabeth line built three new stations while you were 'running' through Hyde Park.",
    athlete: "James W.",
    location: "London",
  },
  {
    content:
      "Got lapped by pizza delivery bikes on the West Side Highway path. Your KOMs are as fake as the Statue of Liberty photos in Times Square.",
    athlete: "Sarah L.",
    location: "New York City",
  },
  {
    content:
      "Even the Cable Cars passed you on that 'hill sprint'. Your power output is lower than Karl the Fog's visibility.",
    athlete: "David C.",
    location: "San Francisco",
  },
  {
    content:
      "Your heart rate zone is as flat as the Bean's reflection. Getting passed by water taxis on the Lakefront Trail isn't a badge of honor.",
    athlete: "Mike R.",
    location: "Chicago",
  },
  {
    content:
      "Nice 'altitude training' - hiding in the REI parking lot doesn't count. Even Casa Bonita cliff divers have better elevation gains.",
    athlete: "Emma T.",
    location: "Denver",
  },
  {
    content:
      "The only segment you're crushing is the poutine line at La Banquise. Mount Royal isn't that steep, you're just that slow.",
    athlete: "Jean P.",
    location: "Montreal",
  },
  {
    content:
      "Getting dropped harder than a Guinness glass at Temple Bar. Phoenix Park deer run faster intervals than you.",
    athlete: "Paddy O.",
    location: "Dublin",
  },
  {
    content:
      "Your cadence is slower than a tourist trying to cross Damrak. Nice job getting passed by grannies on cargo bikes in Vondelpark.",
    athlete: "Lars V.",
    location: "Amsterdam",
  },
];
function NewHome() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("login");

  useEffect(() => {
    const existingToken = localStorage.getItem("strava_token");
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const error = params.get("error");
    const email = params.get("email");

    if (error) {
      if (error === "access_denied") {
        setErrorMessage(
          "You've denied access to Strava. To use Roast My Strava, please allow access to your Strava data."
        );
      } else if (error === "strava_api" || error === "rate_limit") {
        setErrorMessage(
          <>
            Oops! We're experiencing high traffic, please try again a bit later.
            I'll also save your email and notify you when we're back up and
            running. Thanks for your patience! -
            <ErrorMessageLink
              href="https://jasonkuperberg.com/"
              target="_blank"
              rel="noopener"
            >
              Jason
            </ErrorMessageLink>
          </>
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      } else if (error === "internal_server") {
        setErrorMessage(
          "Oops! We hit a wall. We've saved your email and will notify you when we're back up. Please try again later."
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      }
    } else if (token) {
      localStorage.setItem("strava_token", token);
      setIsAuthenticated(true);
    } else if (existingToken) {
      setIsAuthenticated(true);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleLogin = () => {
    if (isEmailValid) {
      setIsLoading(true);
      window.location.href = `https://roast-my-strava-backend-production.up.railway.app/login?email=${encodeURIComponent(
        email
      )}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isEmailValid) {
      handleLogin();
    }
  };

  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "Reddit":
        return <FaSquareReddit color="#ff4500" size={18} />;
      case "Facebook":
        return <FaSquareFacebook color="#1877f2" size={18} />;
      case "Threads":
        return <FaSquareThreads color="#000000" size={18} />;
      case "Instagram":
        return <FaSquareInstagram color="#C13584" size={18} />;
      case "XTwitter":
        return <FaSquareXTwitter color="#000000" size={18} />;
      default:
        return null;
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    initialSlide: Math.floor(Math.random() * comments.length),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  const topTicker = useMemo(() => {
    const shuffledRoasts = [...roastExcerpts].sort(() => Math.random() - 0.5);
    return (
      <TopTickerContent>
        {[...shuffledRoasts, ...shuffledRoasts].map((roast, index) => (
          <React.Fragment key={`top-${index}`}>
            <RoastExcerpt>
              "{roast.content}" - {roast.athlete} in {roast.location}
            </RoastExcerpt>
            <TickerDivider>●</TickerDivider>
          </React.Fragment>
        ))}
      </TopTickerContent>
    );
  }, []);

  return (
    <Container>
      <Helmet>
        <title>
          Roast My Strava - Get Roasted for Your Athletic Achievements
        </title>
        <meta
          name="description"
          content="Connect your Strava account and get a personalized, AI-powered roast of your athletic activities. It's fun, it's free, and it's slightly mean (in a good way)!"
        />
        <meta property="og:title" content="Roast My Strava" />
        <meta
          property="og:description"
          content="Get roasted for your athletic achievements on Strava"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://roastmystrava.com" />
      </Helmet>
      <TopTicker>{topTicker}</TopTicker>
      <MainContent>
        <TabContainer>
          <TabSlider activeTab={activeTab} />
          <Tab
            active={activeTab === "login"}
            onClick={() => setActiveTab("login")}
          >
            Login
          </Tab>
          <Tab
            active={activeTab === "about"}
            onClick={() => setActiveTab("about")}
          >
            About
          </Tab>
        </TabContainer>
        {activeTab === "login" ? (
          <>
            <Title>Roast My Strava</Title>
            <Subtitle>Get Roasted for Your Athletic Achievements</Subtitle>
            <EmailInput
              type="email"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyPress}
              placeholder="Enter your email"
              name="email"
              autoComplete="email"
            />
            <ConnectButton
              src={stravaConnectButton}
              alt="Connect with Strava"
              onClick={handleLogin}
              isValid={isEmailValid}
              isLoading={isLoading}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <SecondarySubtitle>
              Join <b>20,000+ global athletes</b> who have generated{" "}
              <b>50,000+ roasts</b>
            </SecondarySubtitle>
          </>
        ) : (
          <AboutContent>
            <AboutTextContainer>
              <AboutText>
                Hello! I'm{" "}
                <ErrorMessageLink
                  href="https://jasonkuperberg.com/"
                  target="_blank"
                  rel="noopener"
                >
                  Jason
                </ErrorMessageLink>
                , the developer behind Roast My Strava. This app was a fun side
                project I created to share with my friends, but it has gotten
                much more traction than expected.
              </AboutText>
              <AboutText>
                I've enjoyed connecting with athletes around the world —
                cyclists in Rio, ultrarunners in Indiana, run clubs in
                Bengaluru, parkruns in London, and even in my own backyard of
                Central Park in NYC.
              </AboutText>
              <AboutText>
                I am grateful for every athlete who has connected their Strava
                account and shared their results. More to come!
              </AboutText>
            </AboutTextContainer>
            <ImageContainer>
              <AboutImage src={JasonRunning} alt="Jason Running" />
              <ImageCaption
                href=" https://www.strava.com/activities/2917884354"
                target="_blank"
                rel="noopener noreferrer"
              >
                my marathon PR is 2:46
              </ImageCaption>
            </ImageContainer>
          </AboutContent>
        )}
      </MainContent>
      <CommentSection>
        <SliderWrapper>
          <Slider {...sliderSettings}>
            {comments.map((comment, index) => (
              <CommentTile
                key={index}
                onClick={() =>
                  comment.url && window.open(comment.url, "_blank")
                }
                url={comment.url}
              >
                <CommentHeader>
                  <PlatformIcon>
                    {getPlatformIcon(comment.platform)}
                  </PlatformIcon>
                  <CommentAuthor>{comment.author}</CommentAuthor>
                </CommentHeader>
                <CommentContent>{comment.content}</CommentContent>
              </CommentTile>
            ))}
          </Slider>
        </SliderWrapper>
      </CommentSection>
    </Container>
  );
}

export default NewHome;
